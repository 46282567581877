import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectPoolFilterState } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { selectCurrentPoolLocation } from "@kraaft/shared/core/modules/pool/poolSelectors";

import { RoomFilters } from "../modules/filter/filterState";

export const useAppRoomFilters = () => {
  const location = useSelector(selectCurrentPoolLocation);
  const {
    visibility,
    statusId,
    labelIds,
    responsibleId,
    showArchived,
    searchText,
    readingStatus,
  } = useSelector(selectPoolFilterState(location?.poolId));

  const filters = useMemo<RoomFilters>(() => {
    return {
      visibility,
      statusId,
      labelIds,
      responsibleId,
      showArchived,
      searchText,
      readingStatus,
    };
  }, [
    visibility,
    statusId,
    labelIds,
    responsibleId,
    showArchived,
    searchText,
    readingStatus,
  ]);

  return { poolId: location?.poolId, filters };
};
